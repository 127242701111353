<template>
  <v-main>
    <v-responsive class="mx-auto overflow-visible">
      <v-container fluid class="pa-0">
        <v-responsive class="overflow-visible" min-height="90vh">
          <router-view />
        </v-responsive>
      </v-container>
    </v-responsive>
  </v-main>
</template>

<script>
export default {
  name: "BlankView",
};
</script>
